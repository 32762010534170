/*
	Import the API URL from config
*/

import { MM_CONFIG } from '../config.js';

export default {
	/*
		GET /api/v1/games
		GET /api/v1/games/invited
	*/

	getGames: function() {
		console.log(MM_CONFIG);
		return Promise.all(
			[
				axios.get( MM_CONFIG.API_URL + '/games' ),
				axios.get( MM_CONFIG.API_URL + '/games/invited' ),
			]
		)
	},

	/*
		POST /api/v1/games
	*/

	postAddNewGame: function( name, description ) {
		return axios.post( MM_CONFIG.API_URL + '/games', 
			{
				name: name,
				description: description	
			}
		)
		.then( response => {
			return response;
		})
		.catch( error => {
			throw error.response;
		})
	},

	postJoinGameUsingPin: function( gamePin, character_id ) {
		return axios.post( MM_CONFIG.API_URL + '/games/find',
			{
				pin: gamePin,
				character: character_id
			}
		)
		.then( response => {
			return response;
		})
		.catch( error => {
			throw error.response;
		})
	},

	/*
		Admins Game View
		GET /api/v1/admin/games/{gameID}
	*/

	getGame: function( gameID ) {
		return Promise.all(
			[
				axios.get( MM_CONFIG.API_URL + '/admin/games/' + gameID ),
				axios.get( MM_CONFIG.API_URL + '/admin/games/' + gameID + '/characters' ),
			]
		)
	},

	getCharacters: function( gameID ) {
		return axios.get( MM_CONFIG.API_URL + '/admin/games/' + gameID + '/characters' )
		.then( response => {
			return response;
		})
		.catch( error => {
			throw error.response;
		});
	},


	/*
		Players Game View
		GET /api/v1/games/{gamePin}/characters
	*/
	getPlayerGame: function( data ) {
		return Promise.all(
			[
				axios.get( MM_CONFIG.API_URL + '/games/' + data.game_pin + '/user' ),
				axios.get( MM_CONFIG.API_URL + '/games/' + data.game_pin + '/characters' ),
			]
		)
			// return axios.get( MM_CONFIG.API_URL + '/games/' + data.game_pin + '/characters' )
			// .then( response => {

			// 	let gameData = [];
			// 	let usersCharacterData;
			// 	for( let i = 0; i < response.data.length; i++ ) {
			// 		if( response.data[i].user_id == data.user_id ) {
			// 			usersCharacterData = response.data[i];

			// 			(response.data).splice((response.data).indexOf(response.data[i]), 1);
			// 		}
			// 	}
			// 	gameData[0] = usersCharacterData;
			// 	gameData[1] = response.data;

			// 	response.data = gameData;

			// 	return response;
			// })
			// .catch( error => {
			// 	throw error.response;
			// })
	},

	/*
		GET /api/v1/admin/games/{gameID}/gameInventory
	*/
	getGameInventory: function( gameID ) {
		return axios.get( MM_CONFIG.API_URL + '/admin/games/' + gameID + '/gameInventory' )
		.then( response => {
			return response;
		})
		.catch( error => {
			throw error.response;
		});
	},

	/*
		GET /api/v1/admin/games/{gameID}/character-items
	*/
	// getCharactersItems: function( gameID ) {
	// 	return axios.get( MM_CONFIG.API_URL + '/admin/games/' + gameID + '/character-items' )
	// 	.then( response => {
	// 		return response;
	// 	})
	// 	.catch( error => {
	// 		throw error.response;
	// 	});
	// },

	/*
		POST /api/v1/admin/games/{gameID}/items
	*/
	postAddItem: function( gameID, data ) {
		return axios.post( MM_CONFIG.API_URL + '/admin/games/' + gameID + '/items',
			{
				name: data.name, 
				description: data.description,
				item_has_quantity: data.item_has_quantity,
				max_quantity: data.max_quantity,
				game_id: data.game_id
			} 
		)
		.then( response => {
			return response;
		})
		.catch( error => {
			throw error.response;
		});
	},

	/*
		POST /api/v1/admin/games/assignItems
	*/
	postAssignItems: function( data ) {
		return axios.post( MM_CONFIG.API_URL + '/admin/games/assignItems',
			{
				character_id: data.character_id,
				items: data.items
			} 
		)
		.then( response => {
			return response;
		})
		.catch( error => {
			throw error.response;
		});
	},

/*
		POST /api/v1/games/assignItems
	*/
	postPlayerAssignItems: function( data ) {
		return axios.post( MM_CONFIG.API_URL + '/games/assignItems',
			{
				assign_to: data.assign_to_id,
				remove_from: data.remove_from_id,
				items: data.items,
				game_pin: data.game_pin
			} 
		)
		.then( response => {
			return response;
		})
		.catch( error => {
			throw error.response;
		});
	},
	/*

		POST /api/v1/games/assignItems
	*/
	postPlayerSharesItems: function( data ) {
		console.log('shareAPI')
		return axios.post( MM_CONFIG.API_URL + '/games/shareItems',
			{
				assign_to: data.assign_to_id,
				belongs_to: data.belongs_to_id,
				items: data.items,
				game_pin: data.game_pin
			} 
		)
		.then( response => {
			return response;
		})
		.catch( error => {
			throw error.response;
		});
	},

	/*
		DELETE /api/v1/admin/games/items/delete
	*/
	postDeleteItems: function( data ) {
		return axios.post( MM_CONFIG.API_URL + '/admin/games/items/delete',
			{
				items: data.items
			} 
		)
		.then( response => {
			return response;
		})
		.catch( error => {
			throw error.response;
		});
	},

	/*
		POST /api/v1/admin/games/shareItems/delete
	*/
	postDeleteSharedItems: function( data ) {
		return axios.post( MM_CONFIG.API_URL + '/games/shareItems/delete',
			{
				items: data.items
			} 
		)
		.then( response => {
			return response;
		})
		.catch( error => {
			throw error.response;
		});
	},
}