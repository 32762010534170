<template>
	<section class="section section__new-game">
		<form>
			<div class="form-container">
				<div class="form-cell">
					<div class="input-container" :class="name != '' ? 'input-active' : ''">
						<input 
							type="text" 
							name="Name"
							v-model="name" 
							v-on:keyup="validations.name.is_valid = true"
							autocomplete="off"
						>
						<label>Name</label>
					</div>
					<span class="validation" v-show="!validations.name.is_valid">{{ validations.name.text }}</span>
				</div>
				<div class="form-cell">
					<div class="input-container" :class="description != '' ? 'input-active' : ''">
						<input 
							type="text" 
							name="Description"
							v-model="description" 
							v-on:keyup="validations.description.is_valid = true"
							autocomplete="off"
						>
						<label>Description</label>
					</div>
					<span class="validation" v-show="!validations.description.is_valid">{{ validations.description.text }}</span>
				</div>
				<div class="form-cell">
					<button 
						class="button" 
						@click.prevent="submitGame"
						:disabled="createButtonIsDisabled"
					>
						Create Game
					</button>
				</div>
			</div>
		</form>
	</section>
</template>
<script>
	import { mapGetters } from 'vuex';

	export default {
		data() {
			return {
				name: '',
				description: '',
				validations: {
					name: {
						is_valid: true,
						text: ''
					},
					description: {
						is_valid: true,
						text: ''
					},
				}
			}
		},

		computed: {
			...mapGetters('games', {
				gameAddStatus : 'getGameAddStatus',
			}),

			createButtonIsDisabled() {
				let buttonIsDisabled = false;

				if( this.$store.getters.getGameAddStatus == 1 ) {
					buttonIsDisabled = true;
				}

				return buttonIsDisabled;
			}
		},

		methods: {
			submitGame() {
				if( this.validateNewGame() ) {
					this.$store.dispatch( 'games/addGame' , {
						name: this.name,
						description: this.description
					})
				}
			},

			validateNewGame() {
				let newGameIsValid = true;

				if( this.name.trim() == '' ){
					newGameIsValid = false;
					this.validations.name.is_valid = false;
					this.validations.name.text = 'Please enter a game name!';
				}else{
					this.validations.name.is_valid = true;
					this.validations.name.text = '';
				}

				if( this.description.trim() == '' ){
					newGameIsValid = false;
					this.validations.description.is_valid = false;
					this.validations.description.text = 'Please add a helpful description for your game.';
				}else{
					this.validations.description.is_valid = true;
					this.validations.description.text = '';
				}

				return newGameIsValid;
			},

			clearForm() {
				this.name = '';
				this.description = '';
				this.validations = {
					name: {
						is_valid: true,
						text: ''
					},
					description: {
						is_valid: true,
						text: ''
					}
				};
			}
		},

		watch: {
			'gameAddStatus' : function() {
				if( this.gameAddStatus == 2 ) {
					this.clearForm();
					alert('Game Successfully Added!');
				}

				if( this.gameAddStatus == 3 ) {
					this.clearForm();
					alert('Error creating your game.');
				}
			}
		}
	}
</script>