let api_url = '';
let base_url = '';

// switch( process.env.NODE_ENV ){
//   case 'development':
//     // api_url = 'http://murder-mystery.local/api/v1';
//     api_url = 'http://localhost:3000/api/v1';
//     base_url = 'http://localhost:3000/';
//     // base_url = 'http://murder-mystery.local/';
//     // api_url = 'http://192.168.1.140:3000/api/v1';
//   break;
//   case 'production':
//     api_url = 'https://jumpingdev.com/murder-mystery/api/v1';
//     base_url = 'https://jumpingdev.com/murder-mystery/';
//   break;
// }

api_url = location.protocol + '//' + location.host + '/api/v1';
base_url = location.protocol + '//' + location.host + '/';


export const MM_CONFIG = {
  API_URL: api_url,
  BASE_URL: base_url
}