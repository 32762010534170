<template>
	<div id="items">
		<section class="section section__admin-items">
			<div class="items-container">
				<div class="header--area">
					<h2 class="section__title">Items:</h2>
					<button class="button button--add" @click="showAddItemForm = !showAddItemForm">Add Item</button>
				</div>
				<p>Select item(s) to send</p>
				<ul class="item-list" v-if="gameInventory.length > 0">
					<li v-for="item in gameInventory" v-bind:key="item.id">
						<div class="item-line">
							<div class="item-line__header">
								<div class="item">
									<p class="item__name">{{item.name}}</p>
								</div>
								<div class="qty">
									<div class="qty-box">
										<input 
											:id="'qty-' + item.id"
											type="number" 
											name="quantity" 
											value="0" 
											:max="item.quantity ? item.quantity : 1"
										>
										<div class="increments">
											<button 
												class="minus" 
												@click="minusItemFromList(item)"
											>
												<i class="fa fa-minus"></i>
											</button>
											<button 
												class="plus" 
												@click="addItemToList(item)"
												:disabled="item.maxedOut"
											>
												<i class="fa fa-plus"></i>
											</button>
										</div>
									</div>
									<p class="max-qty">Maximum available: {{item.max_quantity - item.quantity_in_play}}/{{item.max_quantity}}</p>
								</div>
							</div>
							<div class="item-line__body">
								<p class="item__description"><span>Description: </span>{{item.description}}</p>
							</div>
						</div>
					</li>
				</ul>
				<p v-else>No items found for this game.</p>
			</div>
			<span class="validation" v-show="!validations.itemList.is_valid">{{ validations.itemList.text }}</span>

			<p v-if="statusMessage">{{statusMessage}}</p>

			<div class="floating-bar" v-if="itemList.length > 0">
				<p class="item-count">{{ itemCount }} selected</p>
				<button class="send" @click="selectActive = !selectActive">
					<i class="fa fa-paper-plane"></i>
					<span>Send</span>
				</button>
				<button class="delete" @click="deleteItem()">
					<i class="fa fa-trash"></i>
					<span>Delete</span>
				</button>

				<form class="characters-selection" :class="selectActive == true ? 'characters-selection--show' : ''">
					<button class="close" @click.prevent="closeSelectBox">
						<i class="fa fa-times"></i>
					</button>

					<div class="form-container">
						<div class="input-container input-container--checkbox" v-for="character in characters" >
							<input 
								type="radio" 
								:value="character.id"
								v-model="character_id"
							>
							<span class="checkmark"></span>
							<label>{{character.name}}</label>
						</div>
						<span class="validation" v-show="!validations.character_id.is_valid">{{ validations.character_id.text }}</span>
						<button 
							class="button button--send" 
							:class=" character_id.length < 1 ? 'disabled' : ''"
							@click.prevent="assignItems"
						>
							<i class="fa fa-paper-plane"></i>
						</button>
					</div>
				</form>

			</div>
			

			<new-item-form 
				v-if="showAddItemForm"
				@closeNewItemForm="closeNewItemForm"
				@refreshItems="refreshItems"
			></new-item-form>
		</section>
	</div>
</template>

<script>
	import {mapGetters} from 'vuex';

	import newItemForm from '../components/NewItem.vue';

	export default {
		data() {
			return {
				statusMessage: '',
				itemList: [],
				character_id: '',
				selectActive: false,
				showAddItemForm: false,
				validations: {
					itemList: {
						is_valid: true,
						text: ''
					},
					character_id: {
						is_valid: true,
						text: ''
					},
				}
			}
		},

		// created() {
		// 	this.$store.dispatch('loadItems', {
		// 		game_id : this.$route.params.id
		// 	})
		// },

		components: {
			newItemForm
		},

		computed: {
			...mapGetters ('games', {
				gameInventory: 'getGameInventory',
				game: 'getGame',
				characters: 'getGameCharacters',
				assignStatus: 'getItemAssignLoadStatus',
				assignMessages: 'getItemAssignMessages',
				deleteStatus: 'getItemDeleteStatus',
			}),

			itemCount() {
				let suffix = 'item';
				let items;
				let count = 0;

				for( let i = 0; i < this.itemList.length; i++) {
					count++;
				}

				if( count > 1 ) {
					suffix = 'item\'s';
				} 

				items = count + ' ' + suffix;

				return items;
			},

			availableItems() {
				let items = this.items;
				let availableItems = items.filter( function(obj) {
					return obj.isInUse === false;
				})

				return availableItems;
			}
		},

		methods: {
			assignItems() {
				if( this.validateAssignItems() ) {
					this.$store.dispatch('games/assignItems', {
						items: this.itemList,
						character_id : this.character_id,
						game_id: this.$route.params.id
					})
				}
			},

			validateAssignItems() {
				let itemsAreValid = true;

				if( this.itemList == [] ) {
					itemsAreValid = false;
					this.validations.itemList.is_valid = false;
					this.validations.itemList.text = 'Please select at least one item.';
				}else{
					this.validations.itemList.is_valid = true;
					this.validations.itemList.text = '';
				}

				if( this.character_id == '' ) {
					itemsAreValid = false;
					this.validations.character_id.is_valid = false;
					this.validations.character_id.text = 'Please select a character to send the item(s) to.';
				}else{
					this.validations.character_id.is_valid = true;
					this.validations.character_id.text = '';
				}

				return itemsAreValid;
			},

			clearForm() {
				this.itemList = [];
				this.character_id = '';
				this.validations = {
					itemList: {
						is_valid: true,
						text: ''
					},
					character_id: {
						is_valid: true,
						text: ''
					},
				};
				this.showAddItemForm = false;

				let inputs = document.querySelectorAll('.item-list input[type="number"');

				for( let i = 0; i < inputs.length; i++ ) {
					inputs[i].value = 0;
				}
			},

			addItemToList(item, e) {
				let siblingInput = document.querySelector('#qty-' + item.id);
				let value = parseInt(siblingInput.value);
			    value = isNaN(value) ? 1 : value;

		    	//Increment input value
		        if(value < item.max_quantity) {
		        	if( (+value + +item.quantity_in_play) < item.max_quantity ) {
		        		value++;
	        		}
		        }

		        //checks if new value is equal to maxqty so we can disable the button
		        if( (+value + +item.quantity_in_play) >= item.max_quantity ) {
		        	item.maxedOut = true;
		        }

				let newItem = {
					'id' : item.id,
					'name' : item.name,
					'quantity' : value
				}

				let contains = this.itemList.filter( function(obj) {
					return obj.id === item.id
				}).length >= 1;

				if( contains === true ) {
					let index = this.itemList.findIndex(x => x.id === item.id);

					//Stop the item from being added more than once if single item
					if( this.itemList[index].quantity < item.max_quantity ) {
						this.itemList[index].quantity++;
					}
					
				} else {
					this.itemList.push(newItem);
				}

			    siblingInput.value = value;
			},

			minusItemFromList(item) {
				//Decrease input value
				let maxQty = item.quantity ? item.quantity : 1;
				let siblingInput = document.querySelector('#qty-' + item.id);
				let value = parseInt(siblingInput.value);
			    value = isNaN(value) ? 0 : value;

			    if( value <= 0 ) {
			    	return;
			    }

				let contains = this.itemList.filter( function(obj) {
					return obj.id === item.id
				}).length >= 1;

				if( contains === true ) {
					if(value > 0) {
						value--;
					}

					let index = this.itemList.findIndex(x => x.id === item.id);

					this.itemList[index].quantity--;

					if( this.itemList[index].quantity === 0 ) {
						this.itemList.splice(index, 1);
					}
				}

			    siblingInput.value = value;

			    //Even if maxed out by minusing 1 we wont be maxed out.
		    	item.maxedOut = false;

			},

			deleteItem() {
				let toDelete = this.itemList.length > 1 ? 'all selected items' : this.itemList[0].name;
				let response = confirm('Are you sure you want to delete ' + toDelete + '?');

				if( response == true ) {
					this.$store.dispatch('games/deleteItems', {
						items: this.itemList,
						game_id: this.$route.params.id
					})
				}
			},

			readMessages( messages ) {
				let formattedMessages = '';

				for( let i = 0; i < messages.length; i++ ) {
					formattedMessages =  messages[i] + '\n' + formattedMessages;
				}

				return formattedMessages;
			}, 

			closeNewItemForm() {
				this.showAddItemForm = false;
			},

			closeSelectBox() {
				this.selectActive = false; 
				this.character_id = '';
			},

			refreshItems() {
				this.$store.dispatch('loadItems', {
					game_id : this.$route.params.id
				})
			},

			isAtMax( item ) {
				// console.log(item)
				let isMaxed = false;
				let itemQty = item.quantity ? item.quantity : 1;
				let inputQty = document.querySelector('#qty-' + item.id);

				if( inputQty ) {
					if( inputQty.max >= itemQty ) {
						isMaxed = true;
					}
				}

				return isMaxed;
			}
		},

		watch: {
			'itemList' : function() {
				if( this.itemList.length == 0 ) {
					this.character_id = '';
					this.selectActive = false;
				}
			},

			'assignStatus' : function() {
				if( this.assignStatus == 1 ) {
					this.statusMessage = 'Assigning your item...'
				}

				if( this.assignStatus == 2 ) {
					this.clearForm();
					this.statusMessage = this.readMessages( this.assignMessages );
				}

				if( this.assignStatus == 3 ) {
					this.clearForm();
					this.statusMessage = this.readMessages( this.assignMessages );
				}
			},

			'deleteStatus' : function() {
				if( this.deleteStatus == 1 ) {
					this.statusMessage = 'Deleting...'
				}

				if( this.deleteStatus == 2 ) {
					this.clearForm();
					this.statusMessage = 'Items deleted.';
				}

				if( this.deleteStatus == 3 ) {
					this.clearForm();
					this.statusMessage = 'Error deleting your item(s). Please try again later.';
				}
			},
		}	
	}
</script>