import CharactersAPI from '../api/characters.js';

//Loading status explained:
// * status = 0 -> No loading has begun
// * status = 1 -> Loading has started
// * status = 2 -> Loading completed successfully
// * status = 3 -> Loading completed unsuccessfully

export const characters = {
	namespaced: true,

	state: {
		characters: [],
		charactersLoadStatus: 0,

		character: {},
		characterLoadStatus: 0,

		characterStatusLoadStatus: 0,

		characterAddLoadStatus: 0,

		characterItemDeleteLoadStatus: 0,

		characterInventoryLoadStatus: 0,
		playerCharacterInventoryLoadStatus: 0,
	},

	actions: {
		loadCharacters( context ) {
			context.commit( 'setLoadStatus', {name:'characters', data: 1} );

			CharactersAPI.getCharacters()
				.then( function(response) {
					context.commit( 'setCharacters', response.data );
					context.commit( 'setLoadStatus', {name:'characters', data: 2} );
				})
				.catch( function() {
					context.commit( 'setCharacters', [] );
					context.commit( 'setLoadStatus', {name:'characters', data: 3} );	
				})
		},

		async loadCharacter( context, data ) {
			context.commit( 'setLoadStatus', {name:'character', data: 1} );

			try {
				const response = await CharactersAPI.getCharacter();

				context.commit( 'setCharacter', response.data );
				context.commit( 'setLoadStatus', {name:'character', data: 2} );
			} catch(error) {
				context.commit( 'setCharacter', [] );
				context.commit( 'setLoadStatus', {name:'character', data: 3} );	
			}
			// CharactersAPI.getCharacter()
			// 	.then( function(response) {
			// 		context.commit( 'setCharacter', response.data );
			// 		context.commit( 'setLoadStatus', {name:'character', data: 2} );
			// 	})
			// 	.catch( function() {
			// 		context.commit( 'setCharacter', [] );
			// 		context.commit( 'setLoadStatus', {name:'character', data: 3} );	
			// 	})
		},

		addCharacter( context, data ) {
			context.commit( 'setLoadStatus', {name:'characterAdd', data: 1} );

			CharactersAPI.postAddNewCharacter( data.name, data.description)
				.then( function(response) {
					context.commit( 'setLoadStatus', {name:'characterAdd', data: 2} );
					context.dispatch( 'loadCharacters' );
				})
				.catch( function(error) {
					throw error;
					console.log(error)
					context.commit( 'setLoadStatus', {name:'characterAdd', data: 3} );	
				})
		},

		deleteCharacter( context, data ) {
			CharactersAPI.destroyCharacter( data.character_id )
				.then( function(response) {
					//Need to refresh the character list now.
					context.dispatch( 'games/refreshGameInventory', {game_id: data.game_id }, {root:true})
					context.dispatch( 'games/getCharactersUsingGameId', {game_id: data.game_id }, {root:true})
				})
				.catch( function(error) {
					throw error;
					console.log(error)
				})
		},

		//Delete Item from character
		//Needs game id
		async removeCharacterItem( context, data ) {
			context.commit( 'setLoadStatus', {name:'characterItemDelete', data: 1} );
			try {
				const response = await CharactersAPI.removeCharacterItem( data );
				// console.log( response ) 
				context.dispatch( 'games/refreshGameInventory', {game_id: data.game_id }, {root:true})
				context.dispatch( 'refreshCharacterInventory', {character_id: data.character_id })
				context.commit( 'setLoadStatus', {name:'characterItemDelete', data: 2} );
			} catch(error) {
				console.log('Error from removeCharacterItem:')
				console.log(error);
				context.commit( 'setLoadStatus', {name:'characterItemDelete', data: 3} );
			}

		},


		//Reload single character inventory
		//Needs character id to do so
		async refreshCharacterInventory( context, data ) {
			context.commit( 'setLoadStatus', {name:'characterInventory', data: 1} );

			try {
				const response = await CharactersAPI.getCharacterInventory( data.character_id );
				// console.log(response.data)
				context.commit( 'games/setCharacterInventory', {character_id: data.character_id, response: response.data}, {root:true} );
				context.commit( 'setLoadStatus', {name:'characterInventory', data: 2} );
			} catch(error) {
				console.log('Error from refreshCharacterInventory:')
				console.log(error);
				context.commit( 'setLoadStatus', {name:'characterInventory', data: 3} );
			}
		},

		async refreshPlayerCharacterInventory( context, data ) {
			context.commit( 'setLoadStatus', {name:'playerCharacterInventory', data: 1} );

			try {
				const response = await CharactersAPI.getCharacterInventory( data.character_id );
				// console.log(response.data)
				context.commit( 'games/setPlayerCharacterInventory', {character_id: data.character_id, response: response.data}, {root:true} );
				context.commit( 'setLoadStatus', {name:'playerCharacterInventory', data: 2} );
			} catch(error) {
				console.log('Error from refreshCharacterInventory:')
				console.log(error);
				context.commit( 'setLoadStatus', {name:'playerCharacterInventory', data: 3} );
			}
		},

		async setCharacterStatus( context, data ) {
			context.commit( 'setLoadStatus', {name:'characterStatus', data: 1} );
			try {
				const response = await CharactersAPI.updateCharacterStatus( data );

				context.commit( 'setCharacter', response.data );
				context.commit( 'setLoadStatus', {name:'characterStatus', data: 2} );
			} catch(error) {
				context.commit( 'setCharacter', [] );
				context.commit( 'setLoadStatus', {name:'characterStatus', data: 3} );	
			}
		},
	},

	mutations: {
		setLoadStatus( state, data ) {
			state[data.name + 'LoadStatus'] = data.data;
		},

		setCharacters( state, data ) {
			state.characters = data;
		},

		setCharacter( state, data ) {
			state.character = data;
		}				
	},

	getters: {
		getCharactersLoadStatus( state ) {
			return state.charactersLoadStatus;
		},

		getCharacterLoadStatus( state ) {
			return state.characterLoadStatus;
		},

		getCharacters( state ) {
			return state.characters;
		},

		getCharacter( state ) {
			return state.character;
		},

		getCharacterAddStatus( state ) {
			return state.characterAddLoadStatus;
		}
	}


}