<template>
	<div id="findAGame">
		<section class="section section__find-a-game">
			<div class="container">
				<p v-text="helpfulMessage"></p>
				<form>
					<div class="form-container">
						<div class="form-cell">
							<div class="game-pin__container">
								<div class="game-pin__half">
									<div class="input-container" :class="firstHalfGamePin != '' ? 'input-active' : ''">
										<input 
											type="number" 
											name="pin-1" 
											v-model="firstHalfGamePin"
											v-on:keyup="checkHalfLength"
											maxlength="4" 
										>
										<label>First half</label>
									</div>
									<span class="validation" v-show="!validations.firstHalfGamePin.is_valid">{{ validations.firstHalfGamePin.text }}</span>
								</div>
								<span class="dash">-</span>
								<div class="game-pin__half">
									<div class="input-container" :class="secondHalfGamePin != '' ? 'input-active' : ''">
										<input 
											type="number" 
											name="pin-2" 
											id="pin-2" 
											v-model="secondHalfGamePin"
											maxlength="4" 
										>
										<label>Second half</label>
									</div>
									<span class="validation" v-show="!validations.secondHalfGamePin.is_valid">{{ validations.secondHalfGamePin.text }}</span>
								</div>
							</div>
							
						</div>
						<div class="form-cell">
							<div class="input-container" :class="character != '' ? 'input-active' : ''">
								<input type="text" name="new-character" v-model="character">
								<label>Character Name</label>
							</div>
							<span class="validation" v-show="!validations.character.is_valid">{{ validations.character.text }}</span>
						</div>
						<div class="form-cell">
							<button class="button" @click.prevent="joinGameUsingPin">Join Game</button>
						</div>
					</div>
				</form>
			</div>
		</section>
	</div>
</template>

<script>
	import {mapGetters} from 'vuex';

	export default {
		data() {
			return {
				helpfulMessage: 'Enter a pin to join a game',
				firstHalfGamePin: '',
				secondHalfGamePin: '',
				character: '',
				validations: {
					firstHalfGamePin: {
						is_valid: true,
						text: ''
					},
					secondHalfGamePin: {
						is_valid: true,
						text: ''
					},
					character: {
						is_valid: true,
						text: ''
					},
				}
			}
		},

		computed: {
			...mapGetters('games', {
				// characters: 'getCharacters',
				searchStatus: 'getGameSearchStatus',
				searchMessage: 'getSearchMessage',
			}),

			fullGamePin() {
				let first = this.firstHalfGamePin
				let second = this.secondHalfGamePin

				return first + '-' + second;
			},
		},

		methods: {
			joinGameUsingPin() {
				if( this.validateJoinRequest() ) {
					this.$store.dispatch('games/joinGameUsingPin', {
						'pin': this.fullGamePin,
						'character': this.character
					})
				}
			},

			validateJoinRequest() {
				let newJoinRequest = true;

				if( this.firstHalfGamePin.trim() == '' ){
					newJoinRequest = false;
					this.validations.firstHalfGamePin.is_valid = false;
					this.validations.firstHalfGamePin.text = 'This information is required!';
				}else{
					this.validations.firstHalfGamePin.is_valid = true;
					this.validations.firstHalfGamePin.text = '';
				}

				if( this.secondHalfGamePin.trim() == '' ){
					newJoinRequest = false;
					this.validations.secondHalfGamePin.is_valid = false;
					this.validations.secondHalfGamePin.text = 'This information is required!';
				}else{
					this.validations.secondHalfGamePin.is_valid = true;
					this.validations.secondHalfGamePin.text = '';
				}

				if( this.character.trim() == '' ){
					newJoinRequest = false;
					this.validations.character.is_valid = false;
					this.validations.character.text = 'Please enter a character name.';
				}else{
					this.validations.character.is_valid = true;
					this.validations.character.text = '';
				}

				return newJoinRequest;
			},

			checkHalfLength(e) {
				let target = e.target;
			    let maxLength = parseInt(target.attributes["maxlength"].value, 10);
			    let myLength = target.value.length;
			    let secondInput = document.getElementById('pin-2');
			    if (myLength >= maxLength) {
			        secondInput.focus();
			    }
			    // Move to previous field if empty (user pressed backspace)
			    else if (myLength === 0) {
			    	target.focus();
			    }
			}
		},

		watch: {
			'searchStatus' : function() {
				if( this.searchStatus == 1 ) {
					this.helpfulMessage = 'Let me check that for you...'
				}

				if( this.searchStatus == 2 ) {
					this.helpfulMessage = 'Success! You\'ve joined this game! Head to the games page to play!'
				}

				if( this.searchStatus == 3 ) {
					this.helpfulMessage = this.searchMessage;
				}
			}
		}
	}
</script>