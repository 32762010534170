import GamesAPI from '../api/games.js';
import router from '../routes.js'

//Loading status explained:
// * status = 0 -> No loading has begun
// * status = 1 -> Loading has started
// * status = 2 -> Loading completed successfully
// * status = 3 -> Loading completed unsuccessfully

export const games = {
	namespaced: true,

	state: {
		games: [],
		gamesLoadStatus: 0,

		invitedGames: [],
		invitedGamesLoadStatus: 0,

		//Admin game stuff
		game: {},
		gameInventory: [],
		gameCharacters : [],
		//Game statuses
		gameLoadStatus: 0,
		gameInventoryLoadStatus: 0,
		gameAddLoadStatus: 0,

		gameSearchLoadStatus: 0,
		searchMessage: '',

		item: {},
		itemLoadStatus: 0,

		itemAddLoadStatus: 0,

		itemAssignLoadStatus: 0,
		itemAssignMessages: [],

		itemDeleteLoadStatus: 0,

		//Player game stuff
		playerGameCharacter: [],

		playersGameLoadStatus: 0,
		playersGameInventoryLoadStatus: 0,
		playersGameItemLoadStatus: 0,
		playerItemAssignLoadStatus: 0,
		playerItemAssignMessages: [],


		
	},

	actions: {
		async loadGames( context ) {
			context.commit( 'setLoadStatus', {name:'games', data: 1} );
			//NB: Dont put games in to an array and push array to state, 
			// games need to be separate entities in state

			try {
				const response = await GamesAPI.getGames();
				let gameCount = 0;

				// console.log(response)

				gameCount = response[0].data.length + response[1].data.length;

				//Checks if the state already has the data so no need to reload it everytime.
				if( context.state.games.length != gameCount.length) {
					//Users games
					for( let i = 0; i < response[0].data.length; i++) {
						context.commit( 'setGames',response[0].data[i]);
					}

					//Invited games
					for( let i = 0; i < response[1].data.length; i++) {
						//Catch for invited games
						context.commit( 'setGames',response[1].data[i]['game']);
					}
				}

				context.commit( 'setLoadStatus', {name:'games', data: 2} );
			} catch(error) {
				console.log(error);
				context.commit( 'setGames', [] );
				context.commit( 'setLoadStatus', {name:'games', data: 3} );	
			}
		},

		async getCharactersUsingGameId( context, data ) {
			try {
				const response = await GamesAPI.getCharacters( data.game_id );
				context.commit( 'setGameCharacters', response.data );
			} catch( error ) {
				console.log('Error getting characters');
				console.log(error);
			}
		},

		// loadInvitedGames( context ) {
		// 	context.commit( 'setLoadStatus', {name:'invitedGames', data: 1} );

		// 	GamesAPI.getInvitedGames()
		// 		.then( function(response) {
		// 			context.commit( 'setInvitedGames', response.data );
		// 			context.commit( 'setLoadStatus', {name:'invitedGames', data: 2} );
		// 		})
		// 		.catch( function() {
		// 			context.commit( 'setInvitedGames', [] );
		// 			context.commit( 'setLoadStatus', {name:'invitedGames', data: 3} );	
		// 		})
		// },

		async loadAdminGameView( context, data ) {
			context.commit( 'setLoadStatus', {name:'game', data: 1} );

			try {
				const response = await GamesAPI.getGame( data.id );

				let user = context.rootGetters['users/getUser']


				if( user.id == response[0].data.host ) {
					// console.log('yarp');
					// console.log(user.id)
					// console.log(response[0].data)
				} else {
					// console.log('narp');
					// console.log(user.id)
					// console.log(response[0].data)
					router.push("/games")
				}
				
				// console.log( response ) 
				//We have all the data now split it up
				//Game data
				context.commit( 'setGame', response[0].data );
				//Game Inventory Data -- Are these necessary??
				context.commit( 'setGameInventory', response[0].data.items );
				//Characters Data
				context.commit( 'setGameCharacters', response[1].data );
				//Characters Items Data -- Are these necessary??
				// console.log(response[1].data)
				// context.commit( 'setGameCharactersInventory', response[1].data.items );

				//All good so show it to the user
				context.commit( 'setLoadStatus', {name:'game', data: 2} )

			} catch(error) {
				console.log('Error from loadGame:')
				console.log(error);

				//Something bad happened clear the state so we have fresh state to try again
				context.commit( 'setGame', [] );
				context.commit( 'setGameInventory', [] );
				context.commit( 'setGameCharacters', [] );
				// context.commit( 'setGameCharactersInventory', [] );

				//Error happened so show it to the user
				context.commit( 'setLoadStatus', {name:'game', data: 3} );	
			}
		},

		async addGame( context, data ) {
			context.commit( 'setLoadStatus', {name:'gameAdd', data: 1} );

			try {
				const response = await GamesAPI.postAddNewGame( data.name, data.description );

				context.commit( 'setLoadStatus', {name:'gameAdd', data: 2} );
				context.dispatch( 'loadGames' );

				alert('Game Successfully Added!');

				router.push({ path: `/admin/games/${response.data.id}` })
			} catch(error) {
				console.log('Error adding game: ')
				console.log(error)
				context.commit( 'setLoadStatus', {name:'gameAdd', data: 3} );	
			}
		},

		async joinGameUsingPin( context, data ) {
			context.commit( 'setLoadStatus', {name:'gameSearch', data: 1} );

			try {

				const response = await GamesAPI.postJoinGameUsingPin( data.pin, data.character );
				console.log( response ) 
				context.commit( 'setSearchMessage', response.data );
				context.commit( 'setLoadStatus', {name:'gameSearch', data: 2} );

				router.push({ path: `/games/${data.pin}` })

			} catch(error) {
				console.log('Error from joinGameUsingPin:')
				console.log(error);
				// context.commit( 'setSearchMessage', error.data );
				context.commit( 'setSearchMessage', 'Game could not be found, please try again later.' );
				context.commit( 'setLoadStatus', {name:'gameSearch', data: 3} );
			}

		},

		async loadPlayerGameView( context, data ) {
			context.commit( 'setLoadStatus', {name:'playersGame', data: 1} );

			try {
				const response = await GamesAPI.getPlayerGame( data );

				// console.log(response)

				context.commit( 'setPlayerGameCharacter', response[0].data );
				context.commit( 'setPlayerGame', response[1].data );

				//All good so show it to the user
				context.commit( 'setLoadStatus', {name:'playersGame', data: 2} )

			} catch(error) {
				console.log('Error from loadPlayerGameView:')
				console.log(error);

				//Something bad happened clear the state so we have fresh state to try again
				context.commit( 'setPlayerGameCharacter', [] );
				context.commit( 'setPlayerGame', [] );

				//Error happened so show it to the user
				context.commit( 'setLoadStatus', {name:'playersGame', data: 3} );	
			}
		},

		//Item Actions
		async addItem( context, data ) {
			context.commit( 'setLoadStatus', {name:'itemAdd', data: 1} );
			try {

				const response = await GamesAPI.postAddItem( data.game_id, data);
				// console.log( response ) 
				context.dispatch( 'refreshGameInventory', {game_id: data.game_id })
				context.commit( 'setLoadStatus', {name:'itemAdd', data: 2} );

			} catch(error) {
				console.log('Error from addItem:')
				console.log(error);
				context.commit( 'setLoadStatus', {name:'itemAdd', data: 3} );
			}

		},

		async refreshGameInventory( context, data ) {
			context.commit( 'setLoadStatus', {name:'gameInventory', data: 1} );

			try {
				const response = await GamesAPI.getGameInventory( data.game_id );
				// console.log(response.data);
				context.commit( 'setGameInventory', response.data );
				context.commit( 'setLoadStatus', {name:'gameInventory', data: 2} );
			} catch(error) {
				console.log('Error from refreshGameInventory:')
				console.log(error);
				context.commit( 'setLoadStatus', {name:'gameInventory', data: 3} );
			}
		},


		//Admin Send items to a player
		async assignItems( context, data ) {
			context.commit( 'setLoadStatus', {name:'itemAssign', data: 1} );
			try {

				const response = await GamesAPI.postAssignItems( data );
				// console.log( response ) 
				context.commit( 'setItemAssignMessages', response.data);
				context.dispatch( 'refreshGameInventory', {game_id: data.game_id});
				context.dispatch( 'characters/refreshCharacterInventory', {character_id: data.character_id}, {root:true});
				context.commit( 'setLoadStatus', {name:'itemAssign', data: 2} );

			} catch(error) {
				console.log('Error from assignItems:')
				console.log(error);
				context.commit( 'setItemAssignMessages', error.data);
				context.commit( 'setLoadStatus', {name:'itemAssign', data: 3} );
			}

		},

		//Player send items to another player
		async playerAssignItems( context, data ) {
			context.commit( 'setLoadStatus', {name:'playerItemAssign', data: 1} );
			try {
				const response = await GamesAPI.postPlayerAssignItems( data );
				context.commit( 'setPlayerItemAssignMessages', response.data);
				//Refresh the characters inventory only no need for game refresh.
				context.dispatch( 'characters/refreshPlayerCharacterInventory', {character_id: data.remove_from_id}, {root:true});
				context.commit( 'setLoadStatus', {name:'playerItemAssign', data: 2} );

			} catch(error) {
				console.log('Error from assignItems:')
				console.log(error);
				context.commit( 'setPlayerItemAssignMessages', error.data);
				context.commit( 'setLoadStatus', {name:'playerItemAssign', data: 3} );
			}

		},

		//Player shares items to another player
		async playerShareItems( context, data ) {
			console.log('module share');
			context.commit( 'setLoadStatus', {name:'playerItemAssign', data: 1} );
			try {
				const response = await GamesAPI.postPlayerSharesItems( data );
				// context.commit( 'setPlayerItemAssignMessages', response.data);
				//Refresh the characters inventory only no need for game refresh.
				// context.dispatch( 'characters/refreshPlayerCharacterInventory', {character_id: data.remove_from_id}, {root:true});
				context.commit( 'setLoadStatus', {name:'playerItemAssign', data: 2} );

			} catch(error) {
				console.log('Error from assignItems:')
				console.log(error);
				context.commit( 'setPlayerItemAssignMessages', error.data);
				context.commit( 'setLoadStatus', {name:'playerItemAssign', data: 3} );
			}

		},

		//Delete Item from game
		async deleteItems( context, data ) {
			context.commit( 'setLoadStatus', {name:'itemDelete', data: 1} );
			try {
				const response = await GamesAPI.postDeleteItems( data );
				// console.log( response ) 
				context.dispatch( 'refreshGameInventory', {game_id: data.game_id })
				context.commit( 'setLoadStatus', {name:'itemDelete', data: 2} );
			} catch(error) {
				console.log('Error from deleteItems:')
				console.log(error.data);
				context.commit( 'setLoadStatus', {name:'itemDelete', data: 3} );
			}

		},

		//Delete Item from game
		async deleteSharedItems( context, data ) {
			// context.commit( 'setLoadStatus', {name:'itemDelete', data: 1} );
			try {
				const response = await GamesAPI.postDeleteSharedItems( data );
				// console.log( response ) 
				// context.commit( 'setLoadStatus', {name:'itemDelete', data: 2} );
			} catch(error) {
				console.log('Error from deleteItems:')
				console.log(error.data);
				// context.commit( 'setLoadStatus', {name:'itemDelete', data: 3} );
			}

		},
	},

	mutations: {
		setLoadStatus( state, data ) {
			state[data.name + 'LoadStatus'] = data.data;
		},

		setGames( state, data ) {
			state.games.push(data);
		},

		setInvitedGames( state, data ) {
			state.invitedGames = data;
		},

		setGame( state, data ) {
			state.game = data;
		},

		setGameInventory( state, data ) {
			state.gameInventory = data;
		},

		setCharacterInventory( state, data ) {
			let characters = state.gameCharacters;

			characters.forEach( character => {
				if( character.id == data.character_id ) {
					character.character_items = data.response.character_items;
				}
			})
		},

		setPlayerCharacterInventory( state, data ) {
			state.playerGameCharacter.character_items = data.response.character_items;
		},

		setGameCharacters( state, data ) {
			state.gameCharacters = data;
		},

		setGameCharactersInventory( state, data ) {
			state.gameCharactersInventory = data;
		},

		setSearchMessage( state, data ) {
			state.searchMessage = data;
		},

		setItemAssignMessages( state, data ) {
			state.itemAssignMessages = data;
		},

		//Players mutuations
		setPlayerGameCharacter( state, data ) {
			state.playerGameCharacter = data;
		},

		setPlayerGame( state, data ) {
			state.gameCharacters = data;
		},

		setPlayerItemAssignMessages( state, data ) {
			state.playerItemAssignMessages = data;
		},

	},

	getters: {
		getGamesLoadStatus( state ) {
			return state.gamesLoadStatus;
		},

		getInvitedGamesLoadStatus( state ) {
			return state.invitedGamesLoadStatus;
		},

		getInvitedGames( state ) {
			return state.invitedGames;
		},

		getGames( state ) {
			return state.games;
		},

		//Single Game Getters
		getGame( state ) {
			return state.game;
		},

		getGameInventory( state ) {
			return state.gameInventory
		},

		getGameCharacters( state ) {
			return state.gameCharacters;
		},

		getGameLoadStatus( state ) {
			return state.gameLoadStatus;
		},

		getGameAddStatus( state ) {
			return state.gameAddLoadStatus;
		},

		//Join game getters
		getGameSearchStatus( state ) {
			return state.gameSearchLoadStatus;
		},


		//
		getSearchMessage( state ) {
			return state.searchMessage;
		},

		getItemsLoadStatus( state ) {
			return state.itemsLoadStatus
		},

		getItem( state ) {
			return state.item
		},

		getItemLoadStatus( state ) {
			return state.itemLoadStatus
		},

		getItemAddLoadStatus( state ) {
			return state.itemAddLoadStatus
		},

		getItemAssignLoadStatus( state ) {
			return state.itemAssignLoadStatus
		},

		getItemAssignMessages( state ) {
			return state.itemAssignMessages
		},

		getItemDeleteStatus( state ) {
			return state.itemDeleteLoadStatus
		},

		//Player Game Getters
		getPlayerGameCharacter( state ) {
			return state.playerGameCharacter;
		},

		getPlayerGameLoadStatus( state ) {
			return state.playersGameLoadStatus;
		},

		getPlayerItemAssignLoadStatus( state ) {
			return state.playerItemAssignLoadStatus
		},

		getPlayerItemAssignMessages( state ) {
			return state.playerItemAssignMessages
		},
	}


}