/*
|-------------------------------------------------------------------------------
| routes.js
|-------------------------------------------------------------------------------
| Contains all of the routes for the application
*/

import Vue from 'vue'
import VueRouter from 'vue-router'

import store from './store.js'

import notFound from './components/global/notFound.vue'
import layout from './pages/Layout.vue';
import home from './pages/Home.vue';
import games from './pages/Games.vue';
import newGame from './pages/NewGame.vue';
import gameAdmin from './pages/GameAdmin.vue';
import game from './pages/Game.vue';
import findGame from './pages/FindAGame.vue';

Vue.use( VueRouter );

export default new VueRouter({
  mode: 'history',
  routes: [
    { path: '*', component: notFound },
    {
        path: '/',
        name: 'layout',
        component: layout,
        children: [
          {
              path: 'home',
              name: 'home',
              component: home
          },
          {
            path: 'games',
            name: 'games',
            component: games
          },
          {
            path: 'games/new',
            name: 'newgame',
            component: newGame
          },
          {
            path: 'games/find',
            name: 'findGame',
            component: findGame
          },
          {
            path: 'admin/games/:id',
            name: 'gameAdmin',
            component: gameAdmin,
          },
          
          {
            path: 'games/:pin',
            name: 'game',
            component: game
          },
        ]
    },
  ]
});