<template>
	<div id="app-layout">
		<div class="header">
			<router-link :to="{ name: 'home'}">
		    	<p class="logo">Murder Mystery <span>Online</span></p>
		    </router-link>
			<navigation></navigation>
		</div>
		<div class="main-content">
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-md-10">
						<router-view :user_id="user_id"></router-view>
					</div>		
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Navigation from '../components/global/Navigation.vue';

  	export default {

  		props: {
  			user_id: {
  				required: true,
  			}
  		},

  		components: {
  		    Navigation
	    },

		mounted(){
			// this.$store.dispatch( 'games/loadGames' );
			this.$store.dispatch( 'users/loadUser' );
			// this.$store.dispatch( 'characters/loadCharacters' );
		}
  	}
</script>