/*
	Import the API URL from config
*/

import { MM_CONFIG } from '../config.js';

export default {
	/*
	GET   /api/v1/user
	*/
	getUser: function(){
		return axios.get( MM_CONFIG.API_URL + '/user' );
	},
}