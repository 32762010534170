<template>
	<div id="players">
		<div class="header--area">
			<h2 class="section__title">Players: </h2>
		</div>

		<div class="players__list" v-if="characters.length != 0">
			<div class="character-card" v-for="character in characters">
				<div class="character-card__header">
					<button 
						class="delete" 
						@click="deleteCharacter(character.id)"
						:disabled="deletingCharacter"
					>
						<i class="fa fa-trash"></i>
					</button>
					<img class="character-card__avatar" :src="$baseUrl + character.avatar">
					<h3 class="character-card__name">{{character.name}}</h3>
					<div class="can-play">
						<p>Status</p>
						<label class="input-container input-container--switch">
							<input 
								type="checkbox" 
								:checked=" character.can_play == 1 ? true : false"
								@click="updateCharacterStatus(character)"
							>
							<span class="slider"></span>
						</label>
					</div>
				</div>
				<div class="inventory-container" v-if="character.character_items">
					<!-- <character-inventory
						:characterInventory="character.character_items"
					></character-inventory> -->
					<ul class="character-card__inventory">
						<li class="character-card__item" v-for="item in character.character_items">
							<div class="character-card__details">
								<p class="character-card__item-name">{{item.item.name}}</p>  
								<p class="character-card__item-qty"><span>Qty:</span> {{item.quantity}}</p>
							</div>
							<button class="delete" @click="deleteCharacterItem(character, item)">
								<i class="fa fa-trash"></i>
							</button>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<p v-else>No Characters found.</p>
	</div>
</template>
<script>
	import { mapGetters } from 'vuex';

	import characterInventory from '../components/characterInventory';

	export default {
		data() {
			return {
				deletingCharacter: false
			}
		},

		computed: {
			...mapGetters('games', {
				characters: 	'getGameCharacters',
			})
		},

		components: {
			characterInventory,
		},

		methods: {
			deleteCharacter( character_id ) {
				this.deletingCharacter = true;
				this.$store.dispatch('characters/deleteCharacter', {
					character_id: character_id,
					game_id: this.$route.params.id
				})
				.then(response => {
					this.deletingCharacter = false;
				})
				.catch(error => {
					this.deletingCharacter = false;
				})
			},

			deleteCharacterItem( character, item ) {
				let response = confirm(' Are you sure you want to delete the ' + item.item.name + ' belonging to ' + character.name + '?');

				if( response ) {
					this.$store.dispatch('characters/removeCharacterItem', {
						item: item,
						character_id: character.id,
						game_id: this.$route.params.id
					})
				}
			},

			updateCharacterStatus(character) {
				let character_id = character.id;
				let can_play = character.can_play == false ? true : false;

				this.$store.dispatch('characters/setCharacterStatus', {
					character_id: character_id,
					can_play: can_play
				})
			}
		}
	}
</script>