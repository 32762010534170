<template>
	<div id="play-game" >
		<section class="section section__game-page">
			<div class="player-screen" v-if="gameStatus == 2">
				<div 
					class="waiting-room"
					v-if="character.can_play == 0"
				>
					<h2 class="section__title">Patience is a virtue.</h2>
					<p>Please wait, the host will let you in soon.</p>
				</div>
				<div class="game-area" v-else>
					<div class="game-pin"><span>Game Pin: </span>{{ gamePin }}</div>
					<div class="character-info">
						<h2 class="character__name">{{character.name}}</h2>
					</div>

					<div class="tabs">
						<div class="tabs__header">
							<div class="tab"></div>
							<div class="tab"></div>
						</div>

						<div class="tabs__container">
							<div class="tabs__body">
								<character-inventory
									:users_character="character"
								></character-inventory>
							</div>
							<div class="tabs__body">
								<h2 class="section__title">Players:</h2>
								<div class="players__list" v-if="players.length != 0">
									<div 
										class="character-card" 
										v-for="player in players"
									>
										<div class="character-card__header">
											<img class="character-card__avatar" :src="$baseUrl + player.avatar">
											<h3 
												class="character-card__name"
												:class="player.user_id == user_id ? 'players-character' : ''"
											>{{player.name}}</h3>
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="loading-screen" v-else>
				<p v-if="gameStatus == 1">Loading the game, please wait.</p>
				<p v-if="gameStatus == 3">Error loading game. Please try again later.</p>
			</div>
		</section>
	</div>
</template>

<script>
	import {mapGetters} from 'vuex';

	import characterInventory from '../components/characterInventory.vue';

	export default {
		props: {
			user_id: {
				required: true,
			}
		},

		data() {
			return {
				gamePin: this.$route.params.pin,
			}
		},

		created() {
			this.$store.dispatch('games/loadPlayerGameView', {
				game_pin: this.$route.params.pin,
			})

		},

		components: {
			characterInventory,
		},

		computed: {
			...mapGetters('games', {
				character: 'getPlayerGameCharacter',
				players: 'getGameCharacters',
				gameStatus: 'getPlayerGameLoadStatus'
			}),

			// ...mapGetters('users', {
			// 	user: 'getUser',
			// }),

			// playersCharacter() {
			// 	if( this.gameStatus == 2 ) {
			// 		let user_id = this.user.id;
					
			// 		let character = this.game.filter( function(obj) {
			// 			return obj.user_id == user_id;
			// 		});

			// 		this.usersCharacter = character[0];
			// 	}
			// }
		}
	}
</script>