<template>
	<div id="games">
		<div class="games">
			<h2 class="section__title">Your games:</h2>
			<span v-show="gamesLoadStatus == 1">Loading</span>
			<span v-show="gamesLoadStatus == 2">Games loaded successfully!</span>
			<span v-show="gamesLoadStatus == 3">Failed to load your Games!</span>

			<div class="games-table">
				<a 
					:href="gameLink(game)"
					class="game__row" 
					v-for="game in games"
				>
					<div class="game__name">{{ game.name }}</div>
					<div class="game__host">Host: <span>{{ hostName(game) }}</span></div>
				</a>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapGetters} from 'vuex';

	export default {
		created() {
			this.$store.dispatch('games/loadGames');
		},

		computed: {
			...mapGetters('games', {
				gamesLoadStatus: 	'getGamesLoadStatus',
				games: 				'getGames',
			}),

			...mapGetters('users', {
				user: 	'getUser',
			}),
	    },

	    methods: {
	    	// gameName( game ) {
	    	// 	return game.name ? game.name : game..name;
	    	// },

	    	gameLink( game ) {
	    		return (game.host == this.user.id) ? this.$baseUrl + 'admin/games/' + game.id : this.$baseUrl + 'games/' + game.pin;
	    	},

	    	hostName( game ) {
	    		//If game.host doesn't exist in first layer then it's not this players game.
	    		console.log(game)
	    		return game.host == this.user.id ? this.user.name : game.user.name; 


	    		
	    	}
	    }
	}
</script>