import Vue from 'vue'
import Vuex from 'vuex'

Vue.use( Vuex );


import { users } from './modules/users.js';
import { characters } from './modules/characters.js';
import { games } from './modules/games.js';


export default new Vuex.Store({
    modules: {
    	users,
    	characters,
    	games,
    },
});