<template>
	<div id="game">
		<section class="section section__game-page" v-if="gameLoadStatus == 2">
			<h4 class="section__prefix">Game Admin Page</h4>
			<h2 class="section__title">{{ game.name }} - [{{game.pin}}]</h2>

			<div class="tabs">
				<div class="tabs__header">
					<div class="tab"></div>
					<div class="tab"></div>
				</div>

				<div class="tabs__container">
					<div class="tabs__body">
						<items></items>
					</div>
					<div class="tabs__body">
						<players></players>
					</div>
				</div>
				
			</div>
		</section>
		<section class="section section__loading" v-if="gameLoadStatus == 1">
			<p class="loading-text">2 ticks whilst we load your game.</p>
		</section>
	</div>
</template>
<script>
	import {mapGetters} from 'vuex';

	import Items from '../components/Items.vue';
	import Players from '../components/Players.vue';

	export default {
		created() {
			this.$store.dispatch( 'games/loadAdminGameView', {
				id: this.$route.params.id
			});
		},

		components: {
			Items,
			Players,
		},

		computed: {
			...mapGetters ('games', {
				game: 				'getGame',
				gameLoadStatus: 	'getGameLoadStatus',
				// charactersItems: 	'getCharactersItems',
				// items: 				'getItems',
				// itemStatus: 		'getItemsLoadStatus',
			}),

			...mapGetters('users', {
				user: 				'getUser',
			})
		},
	}
</script>