<template>
	<div id="new-items">
		<button class="close" @click="closeNewItemForm">
			<i class="fa fa-times"></i>
		</button>
		<div class="new-items-container">
			<h2>Add New Item</h2>
			<form>
				<div class="form-container">
					<div class="form-cell">
						<div class="input-container" :class="name != '' ? 'input-active' : ''">
							<input 
								type="text" 
								name="Name"
								v-model="name" 
								v-on:keyup="validations.name.is_valid = true"
								autocomplete="off" 
							>
							<label>Item Name</label>
						</div>
						<span class="validation" v-show="!validations.name.is_valid">{{ validations.name.text }}</span>
					</div>
					<div class="form-cell">
						<div class="input-container" :class="description != '' ? 'input-active' : ''">
							<input 
								type="text" 
								name="Description"
								v-model="description" 
								v-on:keyup="validations.description.is_valid = true"
								autocomplete="off"
							>
							<label>Description</label>
						</div>
						<span class="validation" v-show="!validations.description.is_valid">{{ validations.description.text }}</span>
					</div>
					<div class="form-cell">
						<div class="input-container input-container--checkbox">
							<label>Will this item have a quantity greater than 1?</label>
							<input type="checkbox" name="item_has_quantity" v-model="item_has_quantity">
							<span class="checkmark"></span>
						</div>
					</div>
					<div class="form-cell" v-if="item_has_quantity">
						<div class="input-container" :class="max_quantity != '' ? 'input-active' : ''">
							<input 
								type="text" 
								name="Quantity"
								v-model="max_quantity" 
								v-on:keyup="validations.max_quantity.is_valid = true"
								autocomplete="off"
							>
							<label>Max Quantity</label>
						</div>
						<span class="validation" v-show="!validations.max_quantity.is_valid">{{ validations.max_quantity.text }}</span>
					</div>
					<div class="form-cell">
						<button 
							class="button" 
							@click.prevent="submitItem"
							:disabled="createButtonIsDisabled"
						>
							Add Item
						</button>
					</div>
				</div>
			</form>
			<p v-if="statusMessage">{{statusMessage}}</p>
		</div>
	</div>
</template>

<script>
	import {mapGetters} from 'vuex';

	export default {
		data() {
			return {
				statusMessage: '',
				name: '',
				description: '',
				item_has_quantity: false,
				max_quantity: '',
				validations: {
					name: {
						is_valid: true,
						text: ''
					},
					description: {
						is_valid: true,
						text: ''
					},
					max_quantity: {
						is_valid: true,
						text: ''
					},
				}
			}
		},

		computed: {
			...mapGetters('games',{
				itemStatus: 'getItemLoadStatus',
				itemAddStatus: 'getItemAddLoadStatus'
			}),

			createButtonIsDisabled() {
				let buttonIsDisabled = false;

				if( this.$store.getters.getItemAddStatus == 1 ) {
					buttonIsDisabled = true;
				}

				return buttonIsDisabled;
			}
		},

		methods: {
			submitItem() {
				if( this.validateNewItem() ) {
					this.$store.dispatch( 'games/addItem' , {
						'name': this.name,
						'description': this.description,
						'item_has_quantity': this.item_has_quantity,
						'max_quantity': this.max_quantity,
						'game_id': this.$route.params.id
					})
				}
			},

			validateNewItem() {
				let newItemIsValid = true;

				if( this.name.trim() == '' ){
					newItemIsValid = false;
					this.validations.name.is_valid = false;
					this.validations.name.text = 'Please enter a name for this item!';
				}else{
					this.validations.name.is_valid = true;
					this.validations.name.text = '';
				}

				if( this.description.trim() == '' ){
					newItemIsValid = false;
					this.validations.description.is_valid = false;
					this.validations.description.text = 'Please enter a description for this item!';
				}else{
					this.validations.description.is_valid = true;
					this.validations.description.text = '';
				}

				if( this.max_quantity.trim() == '' && this.item_has_quantity === true ){
					newItemIsValid = false;
					this.validations.max_quantity.is_valid = false;
					this.validations.max_quantity.text = 'Please enter a quantity for this item!';
				}else{
					this.validations.max_quantity.is_valid = true;
					this.validations.max_quantity.text = '';
				}

				return newItemIsValid;
			},

			clearForm() {
				this.name = '';
				this.description = '';
				this.max_quantity = '';
				this.item_has_quantity = false;
				this.validations = {
					name: {
						is_valid: true,
						text: ''
					},
					description: {
						is_valid: true,
						text: ''
					},
					quantity: {
						is_valid: true,
						text: ''
					}
				};

				this.closeNewItemForm();
			},

			closeNewItemForm() {
				this.$emit('closeNewItemForm');
			},
		},

		watch: {
			'itemAddStatus' : function() {
				if( this.itemAddStatus == 1 ) {
					this.statusMessage = 'Adding your item...'
				}

				if( this.itemAddStatus == 2 ) {
					this.clearForm();
					this.statusMessage = 'Item was successfully added!'
				}

				if( this.itemAddStatus == 3 ) {
					this.statusMessage = 'Error adding your item. Please try again later.';
				}
			}
		}
	}
</script>