import UserAPI from '../api/users.js';

export const users = {
	namespaced: true,
	
	state: {
		userLoadStatus: 0,
		user: {},
	},

	actions: {
		async loadUser( context ) {
			context.commit( 'setUserLoadStatus', 1 );
			try {
				const response = await UserAPI.getUser();
				context.commit( 'setUser', response.data );
				context.commit( 'setUserLoadStatus', 2 );
			} catch( error ) {
				context.commit( 'setUser', {} );
				context.commit( 'setUserLoadStatus', 3 );
			}
		},
	},

	mutations: {
		setUserLoadStatus( state, status ) {
			state.userLoadStatus = status;
		},

		setUser( state, user ) {
			state.user = user;
		}
	},

	getters: {
		getUserLoadStatus( state ) {
			return state.userLoadStatus;
		},

		getUser( state, getters, rootState, rootGetter ) {
			return state.user;
		}
	}
}