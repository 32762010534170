/*
	Import the API URL from config
*/

import { MM_CONFIG } from '../config.js';

export default {
	/*
		GET /api/v1/characters
	*/

	getCharacters: function() {
		return axios.get( MM_CONFIG.API_URL + '/characters' );
	},

	/*
		GET /api/v1/characters/{characterID}
	*/

	getCharacter: function( characterID ) {
		return axios.get( MM_CONFIG.API_URL + '/characters/' + characterID );
	},

	/*
		POST /api/v1/characters
	*/

	postAddNewCharacter: function( name, description ) {
		return axios.post( MM_CONFIG.API_URL + '/characters', 
			{
				name: name,	
			}
		);
	},

	/*
		POST /api/v1/admin/character/removeItems
	*/
	removeCharacterItem: function( data ) {
		return axios.post( MM_CONFIG.API_URL + '/admin/character/removeItems',
			{
				item: data.item,
				character_id: data.character_id
			} 
		)
		.then( response => {
			return response;
		})
		.catch( error => {
			throw error.response;
		});
	},

	/*
		GET /game/character/{character_id}
	*/
	getCharacterInventory: function( data ) {
		return axios.get( MM_CONFIG.API_URL + '/game/character/' + data )
		.then( response => {
			return response;
		})
		.catch( error => {
			throw error.response;
		});
	},

	/*
		UPDATE /game/character/{character_id}
	*/
	updateCharacterStatus: function( data ) {
		return axios.put( MM_CONFIG.API_URL + '/game/character/' + data.character_id, 
			{
				character_id: data.character_id,
				can_play: data.can_play
			}
		)
		.then( response => {
			return response;
		})
		.catch( error => {
			throw error.response;
		});
	},

	/*
		DELETE /game/character/{character_id}
	*/
	destroyCharacter: function( data ) {
		return axios.delete( MM_CONFIG.API_URL + '/game/character/' + data )
		.then( response => {
			return response;
		})
		.catch( error => {
			throw error.response;
		});
	}
}