<template>
	<nav class="navigation">
		<div class="container">
		    <ul class="links">
				<li>
					<router-link :to="{ name: 'games' }">
						Games
					</router-link>
				</li>

				<li>
					<router-link :to="{ name: 'newgame' }">
						Create Game
					</router-link>
				</li>

				<li>
					<router-link :to="{ name: 'findGame' }">
						Join a game
					</router-link>
				</li>
		    </ul>

		    <div class="right">
		    	<img class="avatar" :src="user.avatar" v-show="userLoadStatus == 2"/>
		    </div>
		</div>
	</nav>
</template>
<script>
	export default {
		computed: {
			userLoadStatus(){
				return this.$store.getters['users/getUserLoadStatus'];
			},

			user(){
				return this.$store.getters['users/getUser'];
			}
		}
	}
</script>